<script>
import { defineComponent, ref, watch, nextTick, getCurrentInstance } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { useSaveSectionOnScroll } from './../useSaveSectionOnScroll'
import FormItem from '@/components/Input/FormItem.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import SingleVariant from './SingleVariant.vue'
import MultiVariant from './MultiVariant/index.vue'
import isEqual from 'lodash/isEqual'
import route from '@/router'

/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */
/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
export default defineComponent({
  name: 'TypeAndPrice',
  components: {
    ValidationObserver,
    FormItem,
    Checkbox,
    SingleVariant,
    MultiVariant,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $confirm } = getCurrentInstance().proxy.$root

    const { dirty, el, validationObserver } = useSaveSectionOnScroll(emit)

    /** @type {Ref<ProductFormModel} */
    const model = ref(props.value)
    watch(() => props.value, (product) => {
      model.value = product
    }, { deep: true, immediate: true })
    watch(() => model.value.catalogs, (catalog, old) => {
      isUseVariation.value = !!catalog.attributes && Object.keys(catalog.attributes).length > 0

      if (!isEqual(catalog, old)) {
        nextTick(() => emit('input', model.value))
      }
    }, { deep: true })
    watch(() => [model.value.detail.title, model.value.catalogs.attributes], () => {
      model.value.catalogs.items.forEach(item => {
        item.title = [
          model.value.detail.title,
          (Object.values(item.option || {}) || []).join(', '),
        ].filter(Boolean).join(' - ')
      })
    }, { deep: true })


    const isUseVariation = ref(!!props.value.catalogs.options)
    watch(isUseVariation, (value) => {
      if (!value) {
        model.value.catalogs = {
          ...model.value.catalogs,
          attributes: {},
          options: [],
          items: [{
            ...model.value.catalogs.items[0],
            title: model.value.detail.title,
            price: {
              ...model.value.catalogs.items[0].price,
              currency: route.app._route.meta.title === 'Powerbiz - Add Product Redeem' || route.app._route.meta.title === 'Powerbiz - Edit Product Redeem'
                ? 'Koin' : model.value.catalogs.items[0].price.currency,
            },
          }],
        }
      } else if (model.value.catalogs.items.length > 1 && (route.app._route.meta.title === 'Powerbiz - Add Product Redeem' || route.app._route.meta.title === 'Powerbiz - Edit Product Redeem')) {
        model.value.catalogs = {
          ...model.value.catalogs,
          items: model.value.catalogs.items?.map(v => {
            const temp = {
              ...v,
              price: {
                ...v.price,
                currency: 'Koin',
              },
            }
            return temp
          }),
        }
      }
    }, { immediate: true })
    const useVariationTimestamp = ref(0)
    const onUseVariationChange = (value) => {
      useVariationTimestamp.value = Date.now() // hack to force update :)
      if (isUseVariation.value && !value) {
        $confirm({
          icon: () => null,
          title: 'Hapus Varian',
          content: 'Jika dirubah, data varian yang telah dimasukkan akan berubah dan tipe varian akan hilang dari pilihan varian, lho.',
          okText: 'Ya, Hapus',
          onOk: () => isUseVariation.value = value,
        })
      } else {
        isUseVariation.value = value
      }
    }

    return {
      el,
      dirty,
      validationObserver,
      model,
      isUseVariation,
      onUseVariationChange,
      useVariationTimestamp,
    }
  },
})
</script>

<template>
  <a-card ref="el">
    <ValidationObserver ref="validationObserver">
      <h4 class="">
        {{ $t('product.product_type_and_price') }}
      </h4>
      <div class="text-muted mb-4">
        {{ $t('product.variant_desc') }}
      </div>

      <FormItem>
        <div class="vat-options">
          <label>
            <input v-model="model.detail.vat_type" type="radio" name="vat" value="INCLUDE" />
            <div>{{ $t('product.price-include-vat') }}</div>
          </label>
          <label>
            <input v-model="model.detail.vat_type" type="radio" name="vat" value="EXCLUDE" />
            <div>{{ $t('product.price-exclude-vat') }}</div>
          </label>
          <label>
            <input v-model="model.detail.vat_type" type="radio" name="vat" value="NONE" />
            <div>{{ $t('product.price-no-vat') }}</div>
          </label>
        </div>
      </FormItem>

      <FormItem :key="useVariationTimestamp" tag="div">
        <Checkbox :value="isUseVariation" :label="$t('product.product_variant')" @input="(value) => onUseVariationChange(value)" />
      </FormItem>

      <section v-if="!isUseVariation">
        <SingleVariant v-model="model" :business="business" />
      </section>

      <section v-else>
        <MultiVariant
          v-model="model"
          :business="business"
          @changeuom="val => $emit('changeuom', val)"
        />
      </section>
    </ValidationObserver>
  </a-card>
</template>

<style scoped lang="scss">
.vat-options {
  margin-left: -.5rem;
  margin-right: -.5rem;

  input {
    display: none;
  }

  label {
    padding: 0 .5rem;
    line-height: 1.5;

    div {
      padding: .35rem 1rem;
      border: 1px solid #4D4D4D;
      border-radius: 5rem;
      cursor: pointer;
      transition: .1s all ease-in;
    }

    input:checked {
      + div {
        background: #4D4D4D;
        color: #fff;
      }
    }
  }
}
</style>
