<template>
  <a-row type="flex" :gutter="16">
    <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
      <ol
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="position-sticky p-0 m-0 mt-4"
      >
        <li v-for="(label, key) in sections" :key="key">
          <a class="" @click.prevent>{{ label }}</a>
        </li>
      </ol>
    </a-col>

    <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
      <ValidationObserver ref="validationObserver" slim>
        <a-form novalidate @submit.prevent="saveProducts">
          <fieldset
            v-scroll-spy="{ data: 'activeSection', offset: 125 }"
            class="main"
            :disabled="submitting || loadingDetail"
          >
            <DetailProduct
              v-model="model"
              :categories="categories"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveDetail)"
            />

            <PhotoAndVideo
              v-model="model"
              class="mb-5"
              :permission="permission"
              @change="debouncedSaveImages"
            />

            <TypeAndPrice
              v-model="model"
              :business="business"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveCatalogues)"
              @changeuom="onChangeUom"
            />

            <WeightAndShipping
              v-model="model.units"
              :product="model"
              class="mb-5"
              @input:physical="value => (model.detail.physical = value)"
              @cancelBaseUomChange="
                value => {
                  model.catalogs.items[0].price.uom = value
                }
              "
              @changeUkuranPaket="changeUkuranPaket"
            />
            
            <!-- <WeightAndShipping
              v-model="model.units"
              :product="model"
              class="mb-5"
              @input:physical="value => (model.detail.physical = value)"
              @cancelBaseUomChange="
                value => {
                  model.catalogs.items[0].price.uom = value
                }
              "
              @offscreen="runOnDraft(debouncedSaveWeightAndShipping)"
              @changeUkuranPaket="changeUkuranPaket"
            /> -->

            <!-- <WarehouseAndInventories
              v-model="model"
              :warehouses="warehouses"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveWarehousesAndInventories)"
            /> -->

            <!-- <ChannelPublication 
              v-if="$route.meta.title !== 'Powerbiz - Tambah Produk Channel' && $route.meta.title !== 'Powerbiz - Edit Product Channel'"
              v-model="model.channels"
              :channels="channels"
              :product="model"
            /> -->

            <div
              class="mt-4 mb-5 py-3 border-top text-right position-sticky footer"
            >
              <!-- <div>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  class="px-5 mr-3 ml-auto"
                  @click="onCancel"
                >
                  {{ $t('utils.cancel') }}
                </a-button>

                :disabled="submitting || !$can('group1.product.product-master.WRITE')"
                <a-button
                  size="large"
                  type="primary"
                  class="px-5"
                  html-type="submit"
                  :disabled="submitting || !$can('group1.product.product-master.WRITE') || loadingDetail"
                >
                  <a-icon v-if="submitting" type="loading" class="mr-2" />
                  {{ $t('utils.save') }}
                </a-button>
              </div> -->

              <a-button
                size="large"
                type="primary"
                ghost
                class="px-5 mr-3 ml-auto"
                :disabled="submitting"
                @click="onCancel"
              >
                {{ $t('utils.cancel') }}
              </a-button>

              <a-button
                v-if="permission.includes('WRITE')"
                size="large"
                type="primary"
                class="px-5"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || loadingDetail"
              >
                {{ $t('utils.save') }}
              </a-button>
            </div>
          </fieldset>
        </a-form>
      </ValidationObserver>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue'
import DetailProduct from './DetailProduct.vue'
import { ValidationObserver } from 'vee-validate'
import PhotoAndVideo from './PhotoAndVideo.vue'
import debounce from 'lodash/debounce'
import TypeAndPrice from './TypeAndPrice/index.vue'
import { useFormula } from './TypeAndPrice/Price/useFormula'
import WeightAndShipping from './WeightAndShipping/index.vue'
// import WarehouseAndInventories from './WarehouseAndInventories/index.vue'
// import ChannelPublication from './Channel/ChannelPublication.vue'
import { CREATEPRODUCTREDEEM } from '@/api/channels/distributor'
import { getProductStoreCategories } from '@/api/channels/store'
import { addProductChannel, productPublish, saveProductImages, crudProduct, updateCatalogsProduct, deleteProduct } from '@/api/product'
import { getPriceRange, saveProductPrice } from '@/api/price'
import { getBusinessChannel } from '@/api/business'
import { getInventoryByProductId, getUom, addWarehouse, saveProductInventory } from '@/api/inventory'
import { getShippingUnit, saveProductShipping } from '@/api/shipping'
import { getWarehouses } from '@/api/warehouse'

const { fetchPriceFormula } = useFormula()

const DEFAULT_MODEL = () => ({
  id: null,
  status: 'DRAFT',
  detail: {
    brand_id: null,
    category_id: null,
    condition: 'NEW',
    imported: false,
    long_description: '',
    title: '',
    physical: true,
    vat_type: 'INCLUDE',
  },
  images: [],
  catalogs: {
    attributes: null,
    options: null,
    items: [
      {
        id: null,
        factory_sku: null,
        images: [],
        option: null,
        price: {
          currency: 'IDR',
          value: 0,
          min_qty: 1,
          price_qty: 1,
          uom: 'PCS',
          price_id: null,
          formula_id: null,
          product_variables: [],
        },
        sku: null,
        status: true,
        title: null,
        ext_catalog_id: null,
      },
    ],
    bundling: {
      type: null,
      bundles: [],
    },
  },
  units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
      package: {
        length: null,
        length_unit: 'cm',
        width: null,
        width_unit: 'cm',
        height: null,
        height_unit: 'cm',
        weight: null,
        weight_unit: 'g',
      },
      collapseOpen: false,
    },
  ],
  inventories: [],
  channels: [],
})

const DEFAULT_MODEL_PRODUCT_CHANNEL = () => ({
  product_id: null,
  details: {
    title: null,
    category_id: 0,
    condition: 'baru',
    pre_order: true,
    pre_order_days: 1,
    status: null,
    brand_id: null,
    imported: false,
    is_physical: true,
    basic_uom: 'PCS',
    master_options: [],
    option_attributes: {},
    description: [],
  },
  inventory_units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
    },
  ],
  catalogs: [],
  price_list: [],
  images: [],
})

const parseErrorMessage = msg => {
  let result = 'Coba lagi beberapa saat'
  if (msg && msg.includes('Sku Already in Business')) {
    result = `Kode SKU ${msg.split(' ').pop()} telah terdaftar pada produk lain`
  }
  return 'Oops, produk gagal disimpan! ' + result
}

export default defineComponent({
  name: 'CreateProductForm',
  components: {
    DetailProduct,
    ValidationObserver,
    PhotoAndVideo,
    TypeAndPrice,
    WeightAndShipping,
    // WarehouseAndInventories,
    // ChannelPublication,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      default: () => {},
    },
    permission: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      activeSection: 0,
      saving: {
        detail: false,
      },
      /**
       * @type {import('@/types/product').ProductFormModel}
       */
      model: DEFAULT_MODEL(),
      /** @type {import('@/types/warehouse').Warehouse[]} */
      model_product_channel: DEFAULT_MODEL_PRODUCT_CHANNEL(),
      warehouses: [],
      channels: [],
      shippingUnits: [],
      categories: [],
      submitting: false,
      loadingDetail: false,
    }
  },
  computed: {
    sections() {
      const section = {
        detail: this.$t('product.product_details'),
        gallery: this.$t('product.photo_video'),
        price: this.$t('product.product_type_and_price'),
        shipping: this.$t('product.weight_product_shipping'),
        // inventory: this.$t('product.inventory'),
      }
      return section
    },
    businessId() {
      return (
        this.business?.business_id ||
        (this.$route.query.business_id
          ? String(this.$route.query.business_id)
          : null)
      )
    },
    productId() {
      return this.model.id
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
    httpParams() {
      return {
        business_id: this.businessId,
      }
    },
    isNewProduct() {
      return !this.model.id
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler() {
        this.createProductModel()
      },
    },
    productId: {
      immediate: true,
      handler(value) {
        if (value && this.product) {
          this.fetchShippingAndInventoryUnits()
          this.fetchCatalogPrices()
          this.fetchInventories()
        }
      },
    },
    businessId: {
      immediate: true,
      handler() {
        this.loadingDetail = true
        Promise.all([
          this.fetchCategories(),
          this.fetchWarehouse(),
          this.fetchChannels(),
          this.fetchFormula(),
        ]).finally(() => {
          this.loadingDetail = false
        })
      },
    },
    'model.detail.category_id'(ids) {
      const id = Number(ids[ids.length - 1])
      this.model.detail.category = !id
        ? {}
        : this.findCategory(this.categories, id)
    },
  },
  created() {
    this.debouncedSaveDetail = debounce(this.saveDetail, 50)
    this.debouncedSaveImages = debounce(this.saveImages, 500)
    this.debouncedSaveCatalogues = debounce(this.saveCatalogues, 500)
    // this.debouncedSaveWeightAndShipping = debounce(
    //   this.saveWeightAndShipping,
    //   500,
    // )
    // this.debouncedSaveWarehousesAndInventories = debounce(this.saveWarehousesAndInventories, 500)
  },
  mounted() {
    this.initChannel()
  },
  methods: {
    initChannel() {
      if (
        this.$route.meta.title === 'Powerbiz - Tambah Produk Channel' ||
        this.$route.meta.title === 'Powerbiz - Edit Product Channel'
      ) {
        this.model.channels = [
          {
            business_int_id: 182,
            code: 'distributor_redeem',
            deleted: false,
            id: 625,
            title: 'Kino Redeem',
          },
        ]
      }
    },
    fetchFormula: debounce(async function (value) {
      fetchPriceFormula({ businessId: value })
    }, 500),
    changeUkuranPaket(val, index) {
      this.model.units.forEach((item, idx) => {
        if (idx === index) {
          item.package = {
            ...item.package,
            length_unit: val,
            width_unit: val,
            height_unit: val,
            // weight_unit: val,
          }
        }
      })
    },
    onChangeUom(uom) {
      this.model.catalogs.items.forEach(item => {
        item.price = { ...item.price, uom }
      })
    },
    runOnDraft(fn) {
      if (this.model.status === 'DRAFT') {
        return fn()
      }
    },
    fetchCategories: debounce(async function () {
      const { data: { data } } = await getProductStoreCategories({
        business_id: this.businessId,
        params: {
          business_id: this.businessId,
          channel_id: this.$route.params.id,
        },
      })
      this.categories = data || []
      const parent = this.product?.detail?.category?.id
        ? this.findParent(data, this.product.detail.category.id)
        : []
      if (parent.length) this.model.detail.category_id = parent
    }, 500),
    fetchWarehouse: debounce(async function () {
      const { data: { data } } = await getWarehouses({ business_id: this.businessId })
      this.warehouses = data?.data || []
    }, 500),
    fetchChannels: debounce(async function () {
      const { data: { data } } = await getBusinessChannel(this.businessId)
      this.channels = data?.channels || []
    }, 500),
    async fetchShippingAndInventoryUnits() {
      const fetchShippingUnitPromise = getShippingUnit({
        product_id: this.productId,
        business_id: this.businessId,
      })

      const fetchInventoryUnitPromise = getUom({
        id: this.productId,
        params: { business_id: this.businessId },
      })

      const [
        {
          data: { data: shippingUnitResponse },
        },
        {
          data: { data: inventoryUnitResponse },
        },
      ] = await Promise.all([
        fetchShippingUnitPromise,
        fetchInventoryUnitPromise,
      ])

      const shippingUnits = shippingUnitResponse?.product_units || []
      const inventoryUnits = inventoryUnitResponse?.units || []

      this.model.units = shippingUnits.length
        ? shippingUnits.map(({ unit, ...unitPackage }) => ({
            unit,
            package: unitPackage,
            collapseOpen: false,
            ...(inventoryUnits.find(u => u.unit === unit) || {}),
          }))
        : inventoryUnits.map(({ unit, numerator, denumerator }) => {
            // eslint-disable-next-line no-unused-vars
            const { unit: _, ...unitPackage } =
              shippingUnits.find(u => u.unit === unit) || {}
            return {
              unit,
              numerator,
              denumerator,
              collapseOpen: false,
              package: unitPackage,
            }
          })
    },
    async fetchInventories() {
      const { data: { data } } = await getInventoryByProductId({
          product_id: this.productId,
          params: { business_id: this.businessId },
        })

      this.model.inventories = data?.warehouses || []
    },
    async fetchCatalogPrices() {
      const { data: { data } } = await getPriceRange({
        business_id: this.businessId,
        product_id: this.productId,
      })

      this.model.catalogs.items.forEach(item => {
        const priceData = data.find(p => p.catalog_id === item.id)
        const price = priceData?.price[0]

        if (price) {
          item.price = {
            ...item.price,
            ...price,
          }
        }
      })
    },
    createProductModel() {
      const product = this.product

      if (!product?.product_id) {
        return
      }

      // eslint-disable-next-line no-unused-vars
      const { channels, ...detail } = product.detail

      this.model.id = product.product_id
      this.model.status = product.detail.status;
      (this.model.detail = {
        ...this.model.detail,
        ...detail,
        category_id: [Number(product.detail.category?.id)].filter(Boolean),
        physical:
          product.detail.physical === null ? true : product.detail.physical,
      }),
        (this.model.images = product.images.map(image => ({
          ...image,
          uploading: false,
          deleted: false,
          file: null,
        })))
      this.model.channels = (product.channels || []).map(c => ({
        id: c.id,
        title: c.channel_title,
        code: c.channel_code,
        deleted: false,
      }))

      const catalogItems = product.catalogs.map(item => ({
        ...item,
        price: DEFAULT_MODEL().catalogs.items[0].price,
      }))

      this.model.catalogs = {
        attributes: product.attributes,
        options: product.options,
        items: catalogItems.length
          ? catalogItems
          : [...DEFAULT_MODEL().catalogs.items],
        bundling: {
          type: product.bundling?.bundling_type || null,
          bundles: product.bundling?.bundles || [],
        },
      }

      this.model_product_channel.product_id = product.product_id;
    },
    async saveDetail(ctx, showMessage = true) {
      if (this.saving.detail) return
      this.saving.detail = true

      this.saveOrUpdateDetailProduct(showMessage)

      this.saving.detail = false
    },
    async saveOrUpdateDetailProduct(showMessage = true) {
      // eslint-disable-next-line no-unused-vars
      const { detail, ...model } = this.model
      try {
        const { data: { data } } = await crudProduct({
          business_id: this.business.business_id,
          id: model.id || undefined,
          ...this.model.detail,
          is_new: this.isNewProduct,
        })

        this.model.id = data.id
        // this.model.status = data.status
        if (showMessage) {
          this.$message.success(
            this.model.id
              ? 'Perubahan berhasil disimpan'
              : 'Draft produk berhasil disimpan',
            5,
          )
        }
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    async saveImages(images, showMessage = true) {
      try {
        const undeletedImages = images.filter(image => !image.deleted)
        const product_id = this.model.id
        // Hit service if product_id available
        if (product_id) {
          await saveProductImages({
            product_id,
            business_id: this.business.business_id,
            images: undeletedImages.map(
              ({ id, name, url, ref_id, order, tags }) => ({
                id,
                name,
                url,
                ref_id,
                order,
                tags,
              }),
            ),
            images_delete: images
              .filter(image => image.deleted)
              .map(({ ref_id }) => ref_id),
            status: this.model.status,
          })
        }

        this.model.images = undeletedImages
        if (showMessage && product_id)
          this.$message.success('Gambar produk berhasil disimpan', 5)
      } catch (err) {
        this.$message.error('Oops, gambar produk gagal disimpan', 5)
        throw err
      }
    },
    async saveCatalogues(ctx, showMessage = true) {
      if (!this.productId) {
        // we can save the pending state, once product id is available we can save the catalogues
        return
      }

      try {
        const isCatalogIdFilled = this.model.catalogs.items.every(
          item => !!item.id || (!item.id && !item.status),
        )
        const {
          units,
          catalogs: { bundling },
        } = this.model
        const saveCatalogItems = async () => {
          const { data: { data: { catalogs } } } = await updateCatalogsProduct({
            attributes: this.model.catalogs.attributes,
            options: this.model.catalogs.options,
            business_id: this.business.business_id,
            product_id: this.model.id,
            catalogs: this.model.catalogs.items.map(item => ({
              ...item,
              price: undefined,
              title:
                item.title ||
                [
                  this.model.detail.title,
                  (Object.values(item.option || {}) || []).join(', '),
                ]
                  .filter(Boolean)
                  .join(' - '),
            })),
            bundling:
              bundling && bundling.type && bundling.bundles.length
                ? {
                    bundling_type: bundling.type,
                    bundles: bundling.bundles.map(item => ({
                      catalog_id: item.catalog_id,
                      quantity: item.quantity,
                      uom: item.uom || units[0].unit || null,
                    })),
                    product_id: this.productId,
                  }
                : null,
            // bundling: {
            //   bundles: [],
            //   type: 'SOFT',
            //   product_id: this.model.id,
            // },
          })

          this.model.catalogs.items.forEach((item) => {
            const catalog = catalogs
              ? // ? catalogs[index]
                catalogs.find(c =>
                  item.id ? c.id === item.id : c.sku === item.sku,
                )
              : null

            if (catalog) {
              item.id = catalog.id
            }
          })
        }

        // const saveCatalogPromise = saveCatalogItems()
        // if catalog id is not filled, then wait to save catalog items first, otherwise we can call savePrice parallelly
        // if (!isCatalogIdFilled) await saveCatalogPromise

        if (!isCatalogIdFilled) await saveCatalogItems()

        this.$nextTick(async () => await this.savePrice())
      } catch (err) {
        if (showMessage)
          this.$message.error('Oops, catalog produk gagal disimpan', 5)
        throw err
      }
    },
    async savePrice() {
      const { data: response } = await saveProductPrice({
          business_id: this.business.business_id,
          product_id: this.model.id,
          catalog_price: this.model.catalogs.items
            .filter(i => i.id)
            .map(item => ({
              catalog_id: item.id,
              price: [item.price],
            })),
        },
      )

      const catalogPrices = response?.data?.catalog_price || []

      catalogPrices.forEach(catalogPrice => {
        const { price, catalog_id } = catalogPrice
        const catalog = this.model.catalogs.items.find(c => c.id === catalog_id)

        const mainPrice = price[0]

        catalog.price.price_id = mainPrice.price_id
      })
    },
    async saveWeightAndShipping(ctx, showMessage = true) {
      try {
        await Promise.all([
          this.saveOrUpdateDetailProduct(showMessage),
          saveProductShipping({
            business_id: this.business.business_id,
            product_id: this.model.id,
            product_units: this.model.units.map(unit => ({
              unit: unit.unit,
              ...unit.package,
            })),
            product_units_delete: [],
            status: this.model.status,
          }),
          saveProductInventory({
            business_id: this.business.business_id,
            product_id: this.model.id,
            units: this.model.units.map(({ unit, denumerator, numerator }) => ({
              unit,
              numerator,
              denumerator,
            })),
            units_delete: [],
          }),
        ])
      } catch (err) {
        if (showMessage)
          this.$message.error(
            'Oops, weight dan shipping produk gagal disimpan',
            5,
          )
        throw err
      }
    },
    async saveWarehousesAndInventories(ctx, showMessage = true, warehouses) {
      try {
        await addWarehouse({
          business_id: this.businessId,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            warehouses: warehouses || this.model.inventories,
          },
        })
      } catch (err) {
        if (showMessage)
          this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      }
    },
    async saveChannels(showMessage = true) {
      const channels = this.model.channels
        .filter(c => !c.deleted)
        .map(({ id }) => id)
      const deletedChannels = this.model.channels
        .filter(c => c.deleted)
        .map(({ id }) => id)

      if (!channels.length && !deletedChannels.length) return

      try {
        await addProductChannel({
          business_id: this.businessId,
          product_id: this.model.id,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            channels,
            channels_delete: deletedChannels,
          },
        })
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    getFirstError() {
      const errors = this.$refs.validationObserver.errors
      for (const key in errors) {
        if (errors[key].length) return errors[key][0]
      }
      return null
    },
    async saveProducts() {
      this.submitting = true
      // const valid = await this.$refs.validationObserver.validate()

      // if (!valid) {
      //   this.submitting = false
      //   const firstErrorElement = document.querySelector('.ant-form-explain')
      //   if (firstErrorElement) {
      //     window.scrollTo({
      //       behavior: 'smooth',
      //       top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
      //     })
      //   }
      //   return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      // }

      /** @NOTES : MN-231 */
      const parseWarehouses = [...this.model.inventories].map(item => ({
        ...item,
        catalogs: item.catalogs.map(cat => ({
          ...cat,
          id: ('' + cat.id).startsWith('TEMP_CATALOG_ID_') ? null : cat.id,
        })),
      }))
      // console.log('saveProducts this.model: ', this.model)
      // console.log('saveProducts parseWarehouses: ', parseWarehouses)
      // if (this.model) {
      //   return
      // }

      try {
        if (
          this.$route.meta.title === 'Powerbiz - Tambah Produk Channel' ||
          this.$route.meta.title === 'Powerbiz - Edit Product Channel'
        ) {
          const today = new Date(this.$moment().format('YYYY-MM-DD')).getTime()
          const from = new Date(
            this.$moment(this.model.detail.start_period).format('YYYY-MM-DD'),
          ).getTime()
          const to = new Date(
            this.$moment(this.model.detail.end_period).format('YYYY-MM-DD'),
          ).getTime()
          let inventories = []

          this.model.inventories.forEach(inventory => {
            inventory.catalogs.forEach(catalog => {
              inventories.push({
                catalog_id: catalog.id,
                business_id: this.businessId,
                product_id: this.model.id,
                warehouse_id: inventory.id,
                channel_id: 625,
                uom: catalog.uom,
                pre_order_status: catalog.pre_order_status,
              })
            })
          })

          const data = {
            unit: {
              bussiness_id: this.businessId,
              product_id: this.model.id,
              units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  numerator: item.numerator,
                  denumerator: item.denumerator,
                }
              }),
            },
            inventory: inventories,
            price: {
              channel_id: 625,
              workspace_id: this.$route.query.workspace_id,
              product_id: this.model.id,
              business_id: this.businessId,
              catalog_price:
                this.model.catalogs.items?.map(item => {
                  const { price_qty, min_qty, value, uom } = item.price
                  const temp = {
                    catalog_id: item.id,
                    price: [
                      {
                        currency: 'Koin',
                        price_qty,
                        min_qty,
                        value,
                        uom,
                        price_area_id: '0',
                        customer_category_id: '',
                        effective_date: this.$moment(this.model.detail.start_period),
                      },
                    ],
                  }
                  return temp
                }) || null,
            },
            product: {
              title: this.model.detail.title,
              category: this.model.detail.category_id[0],
              channel_id: 625,
              business_id: this.businessId,
              short_description: this.model.detail.short_description,
              long_description: this.model.detail.long_description,
              base_unit: this.model.units[0]?.unit,
              is_bundling:
                this.model.catalogs.bundling?.bundles.length > 0 ? 1 : 0,
              bundlingType: this.model.catalogs.bundling?.type,
              options:
                this.model.catalogs.options?.map(item => {
                  const temp = {
                    variant: item,
                  }
                  return temp
                }) || null,
              attributes: this.model.catalogs.attributes || [],
              status: today >= from && today <= to ? 'ACTIVE' : 'NOT_ACTIVE',
              is_physical: this.model.detail.physical ? 1 : 0,
              is_new:
                this.model.detail.condition?.toLowerCase() === 'new' ? 1 : 0,
              is_imported: this.model.detail.imported ? 1 : 0,
              master_product_id: this.model.id,
              video_url: this.model.video_url,
              brand_id: '',
              start_period: this.$moment(this.model.detail.start_period),
              end_period: this.$moment(this.model.detail.end_period),
              catalogs:
                this.model.catalogs.items?.map(item => {
                  const temp = {
                    sku: item.sku,
                    brand_sku: '',
                    title: item.title,
                    options: item.option
                      ? this.separateObject(item.option)
                      : [],
                    status:
                      today >= from && today <= to ? 'ACTIVE' : 'NOT_ACTIVE',
                    is_active: today >= from && today <= to ? true : false,
                    short_description: '',
                    long_description: '',
                    master_catalog_id: item.id,
                    images: item.images || [],
                  }
                  return temp
                }) || null,
              images:
                this.model.images.map(item => {
                  const temp = {
                    url: item.url,
                    name: item.name,
                    slug: item.slug,
                    order: item.order,
                    tags: item.tags,
                    ref_id: item.ref_id,
                  }
                  return temp
                }) || [],
              warehouse_ids: this.model.inventories.map(item => {
                return item.id
              }),
            },
            shippment: {
              business_id: this.businessId,
              product_id: this.model.id,
              shipping_units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  ...item.package,
                }
              }),
            },
          }
          const response = await CREATEPRODUCTREDEEM({
            // id: this.$route.params.id,
            business_id:
              this.$route.query.business_id ||
              this.$store.state.user.businessList[0].business_id,
            data,
          })

          if (this.$route.meta.title === 'Powerbiz - Edit Product Channel') {
            if (response.data.status === 'SUCCESS') {
              this.$message.success('Produk berhasil di Simpan!', 5)
              this.$refs.validationObserver.reset()

              if (this.model.status === 'DRAFT') {
                this.model.status = response.data.data.status
                await deleteProduct(this.model.id)
              }

              this.$nextTick(() =>
                setTimeout(() => {
                  this.submitting = false
                  const { workspace_id, business_id, redirect_url } =
                    this.$route.query
                  this.$router.push({
                    path: redirect_url,
                    query: { workspace_id, business_id },
                  })
                }, 150),
              )
            }
          }
        }

        if (this.$route.meta.title !== 'Powerbiz - Edit Product Channel') {
          await Promise.all([
            // this.saveOrUpdateDetailProduct(false),
            this.saveCatalogues(null, false),
            // this.saveWeightAndShipping(null, false),
          ])

          await this.saveWarehousesAndInventories(null, false, parseWarehouses)

          if (this.model.status === 'DRAFT') {
            await productPublish({
              id: this.model.id,
              business_id: this.businessId,
            })
            this.model.status = 'ACTIVE'
          }

          await this.saveChannels(false)

          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.validationObserver.reset()

          this.$nextTick(() =>
            setTimeout(() => {
              this.submitting = false
              // this.businessId
              const { workspace_id, business_id, redirect_url } =
                this.$route.query
              this.$router.push({
                path: redirect_url || '/product',
                query: { workspace_id, business_id },
              })
            }, 150),
          )
        }
      } catch (err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      }
    },

    separateObject(obj) {
      const res = []
      const keys = Object.keys(obj)
      keys.forEach(key => {
        res.push({
          [key]: obj[key],
        })
      })
      return res
    },

    beforeRouteLeave(to, from, next) {
      const isDraftProduct = this.model.status === 'DRAFT'
      const dirty = this.$refs.validationObserver.flags.dirty

      if (!this.permission.includes('WRITE')) return next()

      if (!dirty && !(this.model.id && isDraftProduct)) return next()

      this.$confirm({
        title: 'Data Belum Tersimpan',
        icon: () => null,
        content: isDraftProduct
          ? 'Apakah Anda yakin akan menutup halaman ini, Data yang sudah diisi tidak akan disimpan'
          : 'Perubahan akan hilang saat anda keluar dari halaman ini',
        okText: isDraftProduct
          ? 'Ya, Keluar dari halaman ini'
          : 'Ya, Keluar dari halaman ini',
        class: 'leaving-product-confirm',
        onOk: () => {
          this.deleteDraftProduct()
          next()
        },
      })

      next(false)
    },
    async deleteDraftProduct() {
      const isDraft = this.model.status === 'DRAFT'
      if (isDraft && this.model.id)  await deleteProduct(this.model.id)
    },
    onCancel() {
      const redirectUrl = this.$route.query.redirect_url

      if (redirectUrl) {
        return this.$router.push(redirectUrl)
      }

      return this.$router.push({
        name: 'product.master',
        query: {
          workspace_id: this.$route.query.workspace_id,
          business_id: this.$route.query.business_id,
        },
      })
    },
    findChildren(item, matched) {
      if (item.id === matched) {
        return [item.parent_id, item.id]
      } else if (item.children) {
        let result = null
        let length = item.children.length
        for (let i = 0; i < length; i++) {
          result = this.findChildren(item.children[i], matched)
          if (result) length = i + 1
        }
        return result
      }
      return null
    },
    findParent(array, matched) {
      let found = null
      let length = array.length
      for (let i = 0; i < length; i++) {
        found = this.findChildren(array[i], matched)
        if (found) {
          if (!found.includes(array[i].id)) found.unshift(array[i].id)
          length = i + 1
        }
      }
      return found || []
    },
    findCategory(lists, matched) {
      let length = lists.length
      let result = null
      for (let i = 0; i < length; i++) {
        if (lists[i].id == matched) {
          const { id, name } = lists[i]
          return { id, name }
        } else if (lists[i].children) {
          result = this.findCategory(lists[i].children, matched)
          if (result.id) return result
        }
      }
      return {}
    },
  },
})
</script>

<style scoped lang="scss">
.footer {
  bottom: 0;
  background-color: #f8f9fa;
  // border-top: 1px solid #efefef;
  z-index: 3;
}

ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: 0.5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>

<style lang="scss">
[data-kit-theme='default'] {
  .leaving-product-confirm {
    text-align: center;

    .ant-modal-confirm-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-modal-confirm-content {
      color: #999;
    }

    .ant-modal-confirm-btns {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        margin: 15px 0 0 0 !important;
        height: 45px;
        border: none;
      }
    }
  }
}
</style>
