<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { getProductBrands } from '@/api/channels/store'
import FormItem from '@/components/Input/FormItem.vue'
import TextEditor from '@/components/Input/TextEditor/index.vue'
import { useSaveSectionOnScroll } from './useSaveSectionOnScroll'
import Checkbox from '@/components/Input/Checkbox.vue'

export default defineComponent({
  components: {
    FormItem,
    TextEditor,
    ValidationObserver,
    Checkbox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)

    /**
     * @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel['detail']>}
     */
    const model = ref(props.value.detail)
    watch(
      () => props.value,
      () => {
        model.value = props.value.detail
      },
      { deep: true },
    )

    const subRecurse = subLists => {
      let subParent = []
      subLists.forEach(subList => {
        let prop = 'child'
        let subChildren = []
        const { id, title, slug } = subList
        if (subList.children) {
          subChildren = subRecurse(subList.children)
          if (subChildren.length) prop = 'children'
        }
        subParent.push({
          value: id,
          label: title,
          slug,
          [prop]: subChildren,
        })
      })
      return subParent
    }

    const recurse = lists => {
      let parent = []
      lists.forEach(list => {
        let prop = 'child'
        let children = []
        const { id, slug, title } = list
        if (list.children) {
          children = subRecurse(list.children)
          if (children.length) prop = 'children'
        }
        parent.push({
          value: id,
          label: title,
          slug: slug,
          [prop]: children,
        })
      })
      return parent
    }

    const categoryOptions = computed(() => recurse(props.categories))

    return {
      model,
      categoryOptions,
      validationObserver,
      el,
      dirty,
    }
  },
  data() {
    return {
      endOpen: false,
    }
  },
  mounted() {
    this.getBrands()
  },
  methods: {
    async getBrands() {
      await getProductBrands({
        business_id: this.$route.query.business_id,
        user_id: this.$store.state.user.id,
        params: {
          channel_id: this.$route.params.id,
        },
      })
    },
    disabledStartDate(startValue) {
      const endValue = this.model.end_period
      if (!startValue || !endValue)
        return startValue && startValue < this.$moment().startOf('day')
      return (
        (startValue && startValue.valueOf() < this.$moment().startOf('day')) ||
        startValue.valueOf() > endValue.valueOf()
      )
    },
    disabledEndDate(endValue) {
      const startValue = this.model.start_period
      if (!endValue || !startValue)
        return endValue && endValue < this.$moment().startOf('day')
      return (
        (endValue && endValue.valueOf() < this.$moment().startOf('day')) ||
        startValue.valueOf() >= endValue.valueOf()
      )
    },
    onStartOpenChange(open) {
      if (!open) this.endOpen = true
    },
    onEndOpenChange(open) {
      this.endOpen = open
    },
  },
})
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <a-card ref="el">
      <h4 class="mb-5">
        {{ $t('product.product_details') }}
      </h4>

      <section>
        <div class="font-weight-semibold mb-4">
          {{ $t('product.product_information') }}
        </div>
        <div>
          <label class="mb-1">
            {{ $t('product.product_name') }} <sup class="text-danger">*</sup>
          </label>
          <!-- :label="`${$t('product.product_name')} *`" -->
          <FormItem
            :name="$t('product.product_name')"
            vid="title"
            rules="required"
            class="pcaFormItemLabel"
          >
            <!-- :placeholder="`${$t('product.product_name')} *`" -->
            <a-input v-model="model.title" class="h-48px" focus />
          </FormItem>

          <label class="mb-1">
            {{ $t('product.categories') }} <sup class="text-danger">*</sup>
          </label>
          <FormItem
            :name="$t('product.categories')"
            vid="category"
            rules="required"
          >
            <!-- :placeholder="`${$t('product.categories')} *`" -->
            <a-input-group compact class="">
              <a-cascader
                v-model="model.category_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :options="categoryOptions"
                placeholder=""
              />
            </a-input-group>
          </FormItem>

          <label class="mb-1">
            {{ $t('product.brand') }}
          </label>
          <FormItem :name="$t('product.brand')" vid="brand">
            <!-- :placeholder="$t('product.brand')" -->
            <!-- <a-input
              v-model="model.brand_id"
              class="h-48px"
            /> -->
            <a-input-group compact class="">
              <a-cascader
                v-model="model.brand_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :options="categoryOptions"
                placeholder=""
              />
            </a-input-group>
          </FormItem>

          <a-row class="mt-5">
            <a-col :sm="{ span: 8 }">
              <div class="mb-3">
                <label class="d-block font-weight-semibold mb-3">{{
                  $t('product.item_condition')
                }}</label>
                <FormItem>
                  <Checkbox
                    v-model="model.condition"
                    check-value="USED"
                    uncheck-value="NEW"
                    :label="$t('product.used_condition')"
                    class="ml-2"
                  />
                </FormItem>
              </div>
            </a-col>
            <a-col :sm="{ span: 8 }">
              <div class="mb-3">
                <label class="d-block font-weight-semibold mb-3">{{
                  $t('product.importLabel')
                }}</label>
                <FormItem>
                  <Checkbox
                    v-model="model.imported"
                    :label="$t('product.import')"
                    class="ml-2"
                  />
                </FormItem>
              </div>
            </a-col>
          </a-row>

          <div
            v-if="
              $route.meta.title === 'Powerbiz - Add Product Redeem' ||
                $route.meta.title === 'Powerbiz - Edit Product Redeem'
            "
          >
            <label class="mb-1">
              {{ $t('product.short_description') }}
            </label>
            <FormItem :name="$t('product.short_description')" vid="brand">
              <a-input
                v-model="model.short_description"
                class="h-48px"
                placeholder="Maks. 70 Karakter"
              />
            </FormItem>
          </div>

          <div class="mt-4">
            <label class="d-block font-weight-semibold mb-3">{{
              $t('product.description')
            }}</label>
            <FormItem>
              <TextEditor
                v-model="model.long_description"
                :placeholder="$t('product.write_something')"
              />
            </FormItem>
          </div>
        </div>
      </section>
    </a-card>
  </ValidationObserver>
</template>

<style lang="scss">
.kategori-options ul.ant-cascader-menu {
  height: auto;
  max-height: 180px;
}
</style>
